<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getListDocumentPhotoOnVerification"
)
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'PhotoVerification',
  data () {
    return {
      headers: [
        { value: 'created_at', text: this.$t('createDate') },
        { value: 'sailor', text: this.$t('sailorId'), sortable: false },
        { value: 'sailor_full_name', text: this.$t('sailor'), sortable: false },
        { value: 'status_document', text: this.$t('status') },
        { value: 'event', text: this.$t('actions'), class: 'mw-0', sortable: false }
      ],
      actions: [
        {
          id: 1,
          to: (item) => ({ name: 'sailor', params: { id: item.sailor }, query: { ...this.$route.query } }),
          target: '_blank',
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.documents.photoVerification,
      isLoading: state => state.documents.isLoading
    })
  },
  methods: {
    ...mapActions(['getListDocumentPhotoOnVerification'])
  }
}
</script>
